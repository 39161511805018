import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { maxDevice } from "../utils/media-queries";

const StyledH1 = styled.h1`
  font-weight: bold;
  margin-bottom: 2.441rem;
  /* text-align: center; */
`;

const StyledP = styled.p`
  /* font-weight: bold; */
  font-size: 1.2em;
  padding-bottom: 4rem;
  text-transform: none;
`;

const Wrapper = styled.div`
  padding: 7em;

  @media ${maxDevice.laptopL} {
    padding: 5em;
  }
  @media ${maxDevice.laptop} {
    padding: 3em;
  }
  @media ${maxDevice.tablet} {
    padding: 2em;
  }

  ${(p) => (p.noPadding ? "padding-top: 0px" : "")};
`;

const SectionLayout = ({
  isCentered,
  children,
  description,
  hasLine,
  isSecondary,
  isSwitchable,
  noPadding,
  padding,
  sectionId,
  title,
  customStyle,
}) => {
  const sectionClasses = ` 
    section-layout--padding 
    ${isSecondary ? "bg--secondary " : " "}
    ${isCentered ? "text-center " : " "}  
    ${hasLine ? "progress-line " : " "}  
    ${isSwitchable ? "switchable switchable--switch feature-large " : " "}
  `;
  const hasTitle = title !== "";
  const hasDescription = description !== "";

  const styles = {
    paddingTop: noPadding ? "0px" : "",
    padding: padding ? padding : "",
    ...customStyle,
  };

  console.log(styles);

  return (
    <section
      id={`#${sectionId}`}
      className={`${sectionClasses}`}
      style={styles}
    >
      {hasTitle && (
        <div
          className="container"
          style={{ marginBottom: "1em", textTransform: "capitalize" }}
        >
          <div className="row">
            <div className="col-md-12">
              <StyledH1>{title}</StyledH1>
              {hasDescription && <StyledP>{description}</StyledP>}
            </div>
          </div>
        </div>
      )}
      {children}
    </section>
  );
};

SectionLayout.propTypes = {
  isSecondary: PropTypes.bool,
  sectionId: PropTypes.string,
  title: PropTypes.string,
};

SectionLayout.defaultProps = {
  isSecondary: false,
  sectionId: "#",
  description: "",
  title: "",
};

export default SectionLayout;
