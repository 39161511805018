import React from 'react';
import BreadCrumb from './BreadCrumb';
class BreadCrumbContainer extends React.Component {
    render() {
        // const numberOfSlashes = path.replace(/[^\/]/g, "").length;
        // const numberOfLevels= numberOfSlashes+1;
        let rootname = "";
        let currentname = "";
        if (this.props.parameter) {
            this.props.Categories.map(element => {
              if (this.props.parameter == "category=" + element.id) {
                rootname = "Help";
                currentname = element.title;
              }
            });
          }
          return <BreadCrumb rootname={rootname} currentname={currentname}/>
      }
}
export default BreadCrumbContainer;