import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import SectionLayout from "../SectionLayout";

const StyledDiv = styled.div`
  > blockquote {
    border-left: solid 10px #212121;
    font-size: 1rem;

    padding: 0 1rem;
    margin: 2rem 1rem !important;
  }

  > p {
    font-size: 1.2rem;
    padding-bottom: 2rem;
  }

  > ol {
    font-size: 1.2rem;
    padding-left: 2rem;
  }

  > h3 {
    font-weight: bold;
    padding-bottom: 1rem;
    padding-top: 2rem;
  }
`;

const HelpCenterPost = ({
  html,
  title,
  subtitle,
  subject,
  displayTitle,
  handleBack
}) => {
  return (
    <React.Fragment>
      <nav aria-label="breadcrumb" style={{ paddingLeft: "2em" }}>
        <ol class="breadcrumb" style={{ backgroundColor: "transparent" }}>
          <li class="breadcrumb-item">
            <a onClick={handleBack} style={{ cursor: "pointer" }}>
              Help Center
            </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            <a>{title}</a>
          </li>
        </ol>
      </nav>

      <SectionLayout class="help-post" title={title}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <StyledDiv
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </div>
      </SectionLayout>
    </React.Fragment>
  );
};

export default HelpCenterPost;
