import React from 'react';
import styled from 'styled-components';

import { FiLock, FiGift, FiGlobe, FiBook, FiFolder, FiBookOpen, FiSearch, FiUsers } from 'react-icons/fi';

const IconWrapper = styled.span`
  > svg {
    width: 48px;
    height: 48px;
    stroke: #212121;
  }
`;

const iconCreator = icon => <IconWrapper>{icon}</IconWrapper>;

const icons = {
  privacy: iconCreator(<FiLock/>),
  results: iconCreator(<FiGift/>),
  remote: iconCreator(<FiGlobe/>),
  common_questions: iconCreator (<FiBook/>),
  account_privacy: iconCreator(<FiLock/>),
  experiment: iconCreator(<FiFolder/>),
  visualisations: iconCreator(<FiBookOpen/>),
  participations: iconCreator(<FiUsers/>),
  accuracy: iconCreator(<FiGlobe/>)
}

export default icons;
