import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { FiAirplay } from "react-icons/fi";
import ICONS from "../../utils/icons";

const Categories = [
  { id: "/", subject: "" },
  { id: "category=common_questions", subject: "fa" },
  { id: "category=account_privacy", subject: "acc" },
  { id: "category=experiment", subject: "ex" },
  { id: "category=participations", subject: "pa" },
  { id: "category=visualisations", subject: "vi" }
];

const StyledDiv = styled.div`
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background: none;
  border: none;
  text-align: center;
  stroke-width: 1.2;
  > div {
    height: 100px;
  }
  svg {
    stroke-width: 1.2;
    opacity: 0.8;
  }
  &:hover {
    cursor: pointer;

    /* no select text for browsers */

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    /* no select text for browsers */

    transform: translateY(-2px);

    > div {
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
      border-color: transparent !important;
    }
  }
  @media (max-width: 992px) {
    > div {
      height: 150px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  @media (max-width: 360px) {
    > div {
      height: 180px;
    }
  }
`;
const Card = ({ title, subtitle, icon, onSelect }) => (
  <div className="offset-md-2 col-md-8 ">
    <StyledDiv className="block" onClick={onSelect}>
      <div
        className="feature boxed--border border--round text-center faq-box"
        style={{ marginBottom: "0px" }}
      >
        <div
          className="row justify-content-center align-self-center "
          style={{ padding: "22px 0", height: "100%" }}
        >
          {icon ? (
            <span className="col-md-2" style={{ margin: "auto" }}>
              {icon}
            </span>
          ) : null}
          <div
            className="col-md-8"
            style={{ margin: "auto", textAlign: "center" }}
          >
            <span className="h5 color--primary">{title}</span>
            {subtitle ? <span>{subtitle}</span> : null}
          </div>
        </div>
      </div>
      {/* </Link> */}
    </StyledDiv>
  </div>
);

const ContactCTA = ({}) => (
  <React.Fragment>
    <div className="col-md-12">
      <h4
        className="text-center text--secondary"
        style={{ marginBottom: "2em" }}
      >
        No result matches your search!
      </h4>
    </div>
    <div className="offset-md-3 col-md-6 text-center">
      <Link to="/contact">
        <button className="btn btn--secondary type--uppercase ">
          Speak to an Expert
        </button>
      </Link>
    </div>
  </React.Fragment>
);

const Topics = ({ topics, onSelect, pathX, location }) => {
  const hasTopics = topics.length > 0;
  return (
    <React.Fragment>
      {topics
        .filter(topic => topic.node.frontmatter.title.length > 0)
        .map(({ node }) => {
          const title = node.frontmatter.title || "No Title Specified";
          const subtitle = node.frontmatter.subtitle || "";
          const path = node.frontmatter.path || "/";
          const subject = node.frontmatter.subject || "";
          const displayTitle = node.frontmatter.displayTitle || "";
          const icon = ICONS[node.frontmatter.path.split("/").pop()];
          const url = location.href.split("/").pop();
          let renderCard = false;
          let validparameter = false;

          if (pathX != "/" && url === "help") {
            pathX = "/";
          }
          Categories.map(element => {
            if (pathX == element.id) {
              validparameter = true;
              if (subject == element.subject) {
                renderCard = true;
              }
            }
          });

          if ((validparameter == false) & (subject == "")) {
            renderCard = true;
          }
          if (renderCard) {
            renderCard = false;
            return (
              <Card
                key={node.frontmatter.path}
                title={title}
                icon={icon}
                subtitle={subtitle}
                onSelect={onSelect.bind(null, node.html, title, path, subtitle)}
                path={"/"}
              />
            );
          }
        })}
    </React.Fragment>
  );
};

export default Topics;
