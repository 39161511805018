import React, { Fragment } from "react";
import { graphql, navigate } from "gatsby";

import Layout from "../Layout";
import SectionLayout from "../SectionLayout";
import SEO from "../seo";
import BreadCrumbContainer from "../BreadCrumbContainer";

const Categories = [
  { id: "common_questions", title: "FAQs" },
  { id: "account_privacy", title: "Account & Privacy" },
  { id: "experiment", title: "Experiment Design" },
  { id: "participations", title: "Participation and Crowdsourcing" },
  { id: "visualisations", title: "Visualizations" }
];
class HelpCenterLayout extends React.Component {
  render() {
    /*
     * I have implemented a ternary condition here because
     * this.props.location.href was undefined and throwing
     * an error during the build process
     * */
    const [base, parameter] = this.props.location.href
      ? this.props.location.href.split("?")
      : ["/", null];
    let displayTitle = "";
    let validparameter = false;
    //set displayTitle
    if (parameter) {
      Categories.map(element => {
        if (parameter == "category=" + element.id) {
          displayTitle = element.title;
          validparameter = true;
        }
      });
      // find if there is a match
    }
    const { location, siteTitle } = this.props;
    return (
      <Layout location={location} title={siteTitle}>
        <SEO
          title="Loceye | Understanding Humans"
          keywords={[
            `loceye`,
            `eyetracking`,
            `marketing`,
            `ux`,
            "help",
            "support"
          ]}
        />

        {validparameter ? (
          <Fragment>
            <SectionLayout
              isCentered
              title={displayTitle}
              padding="0"
            ></SectionLayout>{" "}
            <SectionLayout isCentered padding="0">
              <BreadCrumbContainer
                parameter={parameter}
                Categories={Categories}
              />
            </SectionLayout>
          </Fragment>
        ) : (
          <SectionLayout
            isCentered
            description="Common questions and short answers by the Loceye Team"
            title="Help Center"
            padding="0"
          ></SectionLayout>
        )}

        <SectionLayout>
          <div className="container">
            <div class="row" style={{ width: "80%", margin: "auto" }}>
              {this.props.children}
            </div>
          </div>
        </SectionLayout>
      </Layout>
    );
  }
}

export default HelpCenterLayout;
