import React from "react";
import { Link, graphql, navigate } from "gatsby";

import styled from "styled-components";
import Questions from "../pages/questions";
import HelpCenterLayout from "../components/HelpCenter/HelpCenterLayout";
import Post from "../components/HelpCenter/Post";
import Topics from "../components/HelpCenter/Topics";

class Help extends React.Component {
  state = {
    searchTerm: "",
    topics: [],
    selected: "",
    mode: "menu", // menu | post
    pathX:""
  };

  componentDidMount() {
    const { data } = this.props;
    const topics = data.allMarkdownRemark.edges;
    this.setState({ topics, defaultTopics: topics });
  }

  handleSearchTerm = event => {
    const searchTerm = event.target.value;

    this.setState({ searchTerm, mode: "menu" }, this.handleSubmit);
    event.preventDefault();
  };

  handleSubmit = event => {
    const { searchTerm, defaultTopics } = this.state;

    const filteredTopics = this.filterTopics(searchTerm, defaultTopics);

    this.setState({ topics: filteredTopics });

    event.preventDefault();
  };

  filterTopics = (searchTerm, topics) =>
    topics.filter(
      topic => topic.node.frontmatter.title.indexOf(searchTerm) > -1
      
    );

  handleTopicSelect = (html, title, path) => {
    // this.setState({ selected: { html, title }, mode: "post" });
    //navigate("/contact");
    this.setState({ pathX: path});
    const numberofslashes = path.replace(/[^\/]/g, "").length;
    if (numberofslashes == 2){
    navigate("/questions");
    }
    else {
      navigate(path);
    }
    
  };

  handleBack = () => this.setState({ mode: "menu" });
 
  render() {
    const { topics, searchTerm, selected, mode, pathX } = this.state;
    console.log(this.state);
   // console.log("ASDASDAD: "+ this.state.pathX);
    const { data, location } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    let content = null;
    switch (mode) {
      case "menu":
        if(this.state.pathX){
        content = <Topics onSelect={this.handleTopicSelect} topics={topics} pathX={this.state.pathX}/>;
        }
        else {
          content = <Topics onSelect={this.handleTopicSelect} topics={topics} pathX="/"/>;
        }
        break;
      case "post":
        content = (
          <Post
            handleBack={this.handleBack}
            html={selected.html}
            title={selected.title}
            subtitle= {selected.subtitle}
            subject= {selected.subject}
          />
        );
        break;
      default:
        content = null;
    }

    return (
      <HelpCenterLayout
        handleSearchTerm={this.handleSearchTerm}
        handleSubmit={this.handleSubmit}
        location={location}
        searchTerm={searchTerm}
        siteTitle={siteTitle}
      >
        {content}
      </HelpCenterLayout>
    );
  }
}
export const takepath= (currentpath) => {
  this.setState({ pathX: currentpath});
 } 
 
export default Help;
 

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date]}
    ) 
      {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            path
            subtitle
            subject
          }
        }
      }
    }
  }
`;
