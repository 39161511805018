import React from "react";
import styled from "styled-components";
import { graphql, navigate } from "gatsby";

const Categories = [
  { id: "common_questions", title: "FAQs" },
  { id: "account_privacy", title: "Account & Privacy" },
  { id: "experiment", title: "Experiment Design" },
  { id: "participations", title: "Participation and Crowdsourcing" },
  { id: "visualisations", title: "Visualizations" }
];

let categoryname;
function takeName(category) {
  Categories.map(element => {
    if (category == element.id) {
      categoryname = element.title;
    }
  });
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 3em;
`;
var backpointer = {
  color: "black",
  opacity: 1,
  cursor: "pointer"
};
class BreadCrumb extends React.Component {
  render() {
    //we call function takeName to take the display name of category, if a category exists.
    this.props.category ? takeName(this.props.category) : null;
    return (
      <StyledDiv>
        <ul className="breadcrumb">
          <li onClick={() => navigate("/help")} style={backpointer}>
            {this.props.rootname}
          </li>
          {this.props.category ? (
            <li
              onClick={() => navigate("/help?category=" + this.props.category)}
              style={backpointer}
            >
              {categoryname}{" "}
            </li>
          ) : null}
          <li>{this.props.currentname}</li>
        </ul>
      </StyledDiv>
    );
  }
}
export default BreadCrumb;
